import { Box } from 'components/common/Box';
import styled from 'styled-components';
import { StyledPointProps } from './TableHeaderWithActions.d';
import { Typography } from 'components/common/Typography';
import { COLORS } from 'theme/colors';

export const StyledPoint = styled(Box)<StyledPointProps>`
  width: 14px;
  height: 14px;
  border-radius: 2px;
  background-color: ${({ color }) => color};
`;

export const StyledIsin = styled(Typography)`
  fonst-size: 12px;
  line-height: 14px;
  color: ${COLORS.typography.description};
`;

export const StyledName = styled(Typography)`
  fonst-size: 16px;
  line-height: 20px;
  font-weight: 600;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: auto;
`;

export const StyledMoreActionsButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  background-color: unset;
  cursor: pointer;
  transition-duration: 0.2s;

  &:hover {
    svg {
      stroke: ${COLORS.background.hover};
    }
  }

  &:active {
    svg {
      stroke: ${COLORS.accent.hover};
    }
  }
`;
