import { Typography } from 'components/common/Typography';
import styled from 'styled-components';
import { COLORS } from 'theme/colors';

export const StyledPhoto = styled.img`
  width: 100%;
  border-radius: 16px;
`;

export const StyledName = styled(Typography)`
  font-size: 24px;
  line-height: 34px;
`;

export const StyledSubtitle = styled(Typography)`
  font-size: 16px;
  line-height: 19px;
`;

export const StyledYear = styled(Typography)`
  border: 1px solid ${COLORS.stroke.primary};
  border-radius: 4px;
  padding: 5px 12px;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
`;

export const StyledTypography = styled(Typography)`
  font-size: 14px;
  line-height: 25px;
  color: ${COLORS.typography.description};
`;
