import { COLORS } from 'theme/colors';

export const backgroundColors = [
  COLORS.info.selectedBorder,
  COLORS.warning.primary,
  COLORS.error.background,
  COLORS.typography.placeholder,
  COLORS.success.bulletPoint,
];

export const chartLabels = ['1 year', '3 years', '5 years'];

export const scoreValues = [
  { value: 5, name: 'Sehr gut' },
  { value: 4, name: 'Gut' },
  { value: 3, name: 'Mittel' },
  { value: 2, name: 'Schlecht' },
  { value: 1, name: 'Sehr Schlecht' },
];
