import { FC } from 'react';
import { StudiesCardProps } from './StudiesCard.d';
import { Box } from 'components/common/Box';
import {
  StyledName,
  StyledPhoto,
  StyledSubtitle,
  StyledTypography,
  StyledYear,
} from './StudiesCard.styled';
import { Button } from 'components/common/Buttons/Button';

export const StudiesCard: FC<StudiesCardProps> = ({
  name,
  year,
  image,
  subTitle,
  description,
  onRequestStudy,
}) => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between">
      <Box>
        {!!image && <StyledPhoto src={image} />}
        <Box pt={2} display="flex" alignItems="center" gap={1}>
          <StyledName variant="h2">{name}</StyledName>
          {!!year && <StyledYear variant="body">{year}</StyledYear>}
        </Box>
        {(!!subTitle || !!description) && (
          <Box pt={2} display="flex" flexDirection="column" gap={2}>
            {!!subTitle && (
              <StyledSubtitle variant="body">{subTitle}</StyledSubtitle>
            )}
            {!!description && (
              <StyledTypography variant="body">{description}</StyledTypography>
            )}
          </Box>
        )}
      </Box>
      <Box pt={3}>
        <Button onClick={onRequestStudy}>Vollständige Studie anfordern</Button>
      </Box>
    </Box>
  );
};
