import { FC } from 'react';
import { TableHeadProps } from './TableHead.d';
import { TableRow } from '@mui/material';
import {
  StyledFundsNumber,
  StyledSelectedFundsCell,
  StyledTableCell,
  StyledTableCellContainer,
  StyledTableHead,
} from './TableHead.styled';
import { Box } from 'components/common/Box';
import { Typography } from 'components/common/Typography';
import { COLORS } from 'theme/colors';
import { TableHeaderWithActions } from 'components/Modules/ComparisonListContent/TableHeaderWithActions';

export const TableHead: FC<TableHeadProps> = ({
  columns,
  requestReport,
  showAddFeedbackModal,
  showConfirmRemoveFromFundListModal,
  changeFundListStatus,
  showConfirmRemoveFromComparisonListModal,
  changePinnedColumn,
}) => {
  return (
    <StyledTableHead>
      <TableRow>
        <StyledSelectedFundsCell width="210px">
          <Box display="flex" alignItems="center" justifyContent="left" gap={2}>
            <StyledFundsNumber>{columns.length}</StyledFundsNumber>
            <Typography variant="body" color={COLORS.typography.placeholder}>
              Selected funds
            </Typography>
          </Box>
        </StyledSelectedFundsCell>
        {columns.map((item, index) => (
          <StyledTableCell key={index} align="left" width="325px">
            <StyledTableCellContainer>
              <TableHeaderWithActions
                data={item}
                requestReport={requestReport}
                showConfirmRemoveFromFundListModal={
                  showConfirmRemoveFromFundListModal
                }
                changeFundListStatus={changeFundListStatus}
                showAddFeedbackModal={showAddFeedbackModal}
                showConfirmRemoveFromComparisonListModal={
                  showConfirmRemoveFromComparisonListModal
                }
                changePinnedColumn={changePinnedColumn}
              />
            </StyledTableCellContainer>
          </StyledTableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
};
